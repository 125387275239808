.fake-google-pay-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: white; /* Use Apple Pay's blue color */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid #5f6368; /* Add black border */
    width: 100%;
    min-height: 62px;
  }
  
  .fake-google-pay-button:hover {
    background-color: #2dccd3; /* Darker shade on hover */
  }
  
  .google-pay-logo {
    width: 96px;
    height: 37px;
    margin: auto;
    background-image: url('../img/google_pay_logo.png');
    background-size: cover; /* Adjust as needed */
  }
  
  
  .button-label {
    font-weight: bold;
    font-size: 16px;
  }
  