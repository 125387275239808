.donation-area.placeholder .fixed-bg {
    background-position: right top;
    background-image: url("../img/women_hugging.jpg");
    background-size: cover;
    background-attachment: fixed;
  }

  .donation-area.placeholder {
    width: 100vw; /* 100% of the viewport width */
    height: 100vh; /* 100% of the viewport height */
}

.donation-area.placeholder .fixed-bg::after {
  opacity: 0.4;
}