
/* ============================================================== 
     # Causes Single 
=================================================================== */
.causes-single-area .sidebar aside {
    padding: 30px;
    background: #ffffff;
    box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  }
  
  .causes-single-items aside .item {
    margin-bottom: 30px;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 30px;
  }
  
  .causes-single-items aside .item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }
  
  .causes-single-items .progress-box .progress {
    height: 5px;
    overflow: inherit;
  }
  
  .causes-single-items .progress-box .progress .progress-bar {
    background: #2dccd3;
    position: relative;
    z-index: 1;
    overflow: inherit;
  }
  
  .hibiscus .causes-single-items .progress-box .progress .progress-bar {
      background: #bf3654;
  }
  
  .causes-single-items .progress-box .progress-bar::after {
    position: absolute;
    right: 0;
    top: -5px;
    content: "";
    height: 15px;
    width: 2px;
    background: #2dccd3;
  }
  
  .hibiscus .causes-single-items .progress-box .progress-bar::after {
      background: #bf3654;
  }
  
  .causes-single-items .progress-box p {
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .causes-single-items .progress-box > span {
    font-weight: 700;
    margin-top: 10px;
    display: inline-block;
  }
  
  .causes-single-items .item h4 {
    font-weight: 700;
    margin-bottom: 25px;
  }
  
  .causes-single-items .item  ul li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: 10px;
  }
  
  .causes-single-items .item ul {
    margin-top: -10px;
  }


  
  .causes-single-items .item ul li button {
    display: flex;
    font-weight: 800;
    padding:15px 40px;
    border-radius: 10px;
    font-size: 17px;
    width: 100%;
    color: #54585a;
  }

  @media (max-width: 600px) {
    .causes-single-items .item ul li button {
      padding: 15px;
      text-align: center; /* Center the content horizontally */
    }
}

  .causes-single-items .item ul.group li {
    width: 33%;
  }

  @media (max-width: 1400px) { 
    .causes-single-items .item ul.group li {
      width: 50%;
    }
  }


  ol, ul {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 1rem;
    margin-right:1rem;
  }


  .causes-single-items .item ul li button.active {
    background: #2dccd3;
    color: #ffffff;
  }
  
  .hibiscus .causes-single-items .item ul li button.active {
      background: #bf3654;
  }
  
  .causes-single-items aside .item form input {
    margin-top: 20px;
    border: 1px solid #e7e7e7;
    border-radius: 30px;
    text-align: center;
  }
  
  .causes-single-items aside .item form > button {
    width: 100%;
    background: transparent;
    border: 2px solid #2dccd3 !important;
    margin-top: 15px;
    padding: 9px;
    border-radius: 30px;
    font-weight: 700;
  }
  
  .hibiscus .causes-single-items aside .item form > button {
      border: 2px solid #bf3654 !important;
  }
  
  .causes-single-items aside .item > a {
    color: #2dccd3;
    text-transform: uppercase;
    font-weight: 800;
  }
  
  .hibiscus .causes-single-items aside .item > a {
      color: #bf3654;
  }
  
  .causes-single-items aside .item .donator {
    display: flex;
    border-bottom: 1px dashed #cccccc;
    margin-bottom: 20px;
    padding-bottom: 15px;
  }
  
  .causes-single-items aside .item .donator:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .causes-single-items aside .item .donator .thumb  img {
    max-width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .causes-single-items aside .item .donator .info ul li {
    display: block;
    margin-top: 0;
    font-size: 14px;
  }
  
  .causes-single-items aside .item .donator .info ul li strong {
    font-weight: 700;
    color: #232323;
    font-size: 16px;
  }
  
  .causes-single-items aside .item .donator .info h5 {
    font-weight: 700;
  }
  
  .causes-single-items .content img {
    margin-bottom: 30px;
  }
  
  .causes-single-items .content .top-entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .causes-single-items .content .top-entry i {
    margin-right: 3px;
    color: #2dccd3;
  }
  
  .hibiscus .causes-single-items .content .top-entry i {
      color: #bf3654;
  }
  
  .causes-single-items .content .top-entry i.i.fas.fa-clock {
    font-weight: 500;
  }
  
  .causes-single-items .content h2,
  .causes-single-items .content h3,
  .causes-single-items .content h4,
  .causes-single-items .content h5 {
    font-weight: 700;
  }
  
  .causes-single-items .content {
    padding-right: 35px;
  }
  
  .causes-single-items .content a {
    margin-top: 8px;
  }
  
  .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-lg {
    font-size: 1.5em; /* Adjust the size as needed */
  }