.fake-apple-pay-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: white; /* Use Apple Pay's blue color */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid black; /* Add black border */
    width: 100%;
  }
  
  .fake-apple-pay-button:hover {
    background-color: #2dccd3; /* Darker shade on hover */
  }
  
  .apple-pay-logo {
    width: 90px;
    height: 40px;
    margin: auto;
    background-image: url('../img/apple-pay-logo.png');
    background-size: cover; /* Adjust as needed */

  }
  
  
  .button-label {
    font-weight: bold;
    font-size: 16px;
  }
  